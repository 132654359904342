import axios from "axios";
import { motion, useSpring, useTransform, useViewportScroll } from "framer-motion";
import React, { useState, useEffect, useRef } from "react";
import AnimatedSection from "../Components/AnimatedSection";
// import { Modal, Button } from 'react-bootstrap';
import Loader from "../Components/Loader";
import BackdropAnimation from "../Components/BackdropAnimation";
import HeightAnimatedSection from "../Components/HeightAnimatedSection";
import MoreSetting from "../Components/MoreSetting";
import BackdropGlow from "../Components/BackdropGlow";
import useAuth from "../hooks/useAuth";
import { AuthFetch, AuthFetch2, refresh, signUpWithGoogle, signUpWithMicrosoft } from "../lib/auth";
import { useNavigate } from "react-router-dom";
import { stagger, useAnimate } from "framer-motion";
import CornerWrapAnimation from "../Components/CornerWrapAnimation";


function Homepage() {
   const isAuthenticated = useAuth();
   const navigate = useNavigate()
   const Mocksite = [
      {
         site_text: "Try for Yourself",
         site_url:
            "#",
         sitePara: "Generate a PPC ad for your website now.",
      },
      {
         site_text: "It's Completely Free",
         site_url:
            "#",
         sitePara: "See how AI makes ad creation effortless.",
      },
      {
         site_text: "Your Page here",
         site_url:
            "#",
         sitePara: "Let's customize your journey with us.",
      },
      {
         site_text: "Launch in 5 Minutes",
         site_url:
            "#",
         sitePara: "Try for yourself by entering a URL above.",
      },
   ];
   const { scrollY } = useViewportScroll();
   const scale = useTransform(scrollY, [0, 1000], [1, 0.9]);
   const smoothScale = useSpring(scale, { stiffness: 50, damping: 10 });
   const [showModal, setShowModal] = useState(false);
   const [isActive, setIsActive] = useState(false);
   const handleClose = () => setShowModal(false);
   const labels = document.querySelectorAll('.label-sc');
   labels.forEach((label) => {
      label.addEventListener('click', () => {
         labels.forEach((lbl) => lbl.classList.remove('checked'));
         label.classList.add('checked');
      });
   });

   const [showCustomInstructions, setShowCustomInstructions] = useState(false);
   const [isVisible, setIsVisible] = useState(false);
   useEffect(() => {
      const timer = setTimeout(() => setIsVisible(true), 1400);
      return () => clearTimeout(timer); // Clean up the timer
   }, []);

   const [instructions, setInstructions] = useState(""); // State to hold the textarea value
   const maxInstructionsLength = 200; // Maximum character limit

   const handleInstructionsChange = (e) => {
      const value = e.target.value.trim();
      const field = selectedMenu.toLowerCase();

      if (value.length <= maxInstructionsLength) {
         setInstructions(value);

         setCampaignData((prev) => ({
            ...prev,
            [campaignMode]: {
               ...prev[campaignMode],
               menuItems: {
                  ...prev[campaignMode].menuItems,
                  [selectedMenu]: value === "" ? null : value,
               },
            },
         }));

         setCheckedItems((prev) => ({
            ...prev,
            [field]: value !== "",
         }));
      }
   };

   const [checkedItems, setCheckedItems] = useState({
      all: true,
      headline: true,
      descriptions: true,
      sitelinks: true,
      callout: true,
      snippets: true,
      sheetURL: true,
   });
   const [numberOfAds, setNumberOfAds] = useState(1);
   const [numberOfKeywords, setNumberOfKeywords] = useState(5);
   const [totalCost, setTotalCost] = useState(250);
   const [campaignMode, setCampaignMode] = useState("Broad search campaign");
   const [selectedMenu, setSelectedMenu] = useState("Headlines");

   const [campaignData, setCampaignData] = useState({
      "Brand name campaign": {
         menuItems: {
            Headlines: `- Capitalize for readability.\n- Engaging, to the point.\n- Aim for CTR and CTA.\n- 30 characters max.`,
            Descriptions: `- Capitalize for readability.\n- Clear, concise, actionable.\n- Focus on product value.\n- 90 characters max.`,
            Sitelinks: `- Capitalize for readability.\n- Name matches page intent.\n- Descriptions: 7-12 words max.\n- Provide clear final URLs.`,
            Snippets: `- Select predefined headers.\n- Each header: 4 relevant items.\n- Align with ad content.\n- Focus on clarity and engagement.`,
            Callouts: `- Capitalize for readability.\n- Highlight unique selling points.\n- Keep it concise, 5-20 characters.\n- Encourage clicks with impact.`,
            Keywords: "- Medium long-tail: 3-4 words each.\n- 1 keyword of 3 words and 2 of 4 words.\n- No hyphens & no consecutive long words.\n- Maximum 5 words per keyword."
         },
      },
      "Broad search campaign": {
         menuItems: {
            Headlines: `- Capitalize for readability.\n- Engaging, to the point.\n- Aim for CTR and CTA.\n- 30 characters max.`,
            Descriptions: `- Capitalize for readability.\n- Clear, concise, actionable.\n- Focus on product value.\n- 90 characters max.`,
            Sitelinks: `- Capitalize for readability.\n- Name matches page intent.\n- Descriptions: 7-12 words max.\n- Provide clear final URLs.`,
            Snippets: `- Select predefined headers.\n- Each header: 4 relevant items.\n- Align with ad content.\n- Focus on clarity and engagement.`,
            Callouts: `- Capitalize for readability.\n- Highlight unique selling points.\n- Keep it concise, 5-20 characters.\n- Encourage clicks with impact.`,
            Keywords: "- Medium long-tail: 3-4 words each.\n- 1 keyword of 3 words and 2 of 4 words.\n- No hyphens & no consecutive long words.\n- Maximum 5 words per keyword."
         },
      },
   });
   const handleCampaignChange = (mode) => {
      setCampaignMode(mode);
      if (!campaignData[mode].menuItems[selectedMenu]) {
         const firstMenuItem = Object.keys(campaignData[mode].menuItems)[0];
         setSelectedMenu(firstMenuItem);
      }
   };

   // Map campaign mode to a class for dynamic styling
   const glowClass =
      campaignMode === "Broad search campaign"
         ? "backdrop-elm-white"
         : campaignMode === "Brand name campaign"
            ? "backdrop-elm-white"
            : "backdrop-elm-white";
   const campaignModes = [
      "Brand name campaign",
      "Broad search campaign",
   ];

   const currentModeIndex = campaignModes.indexOf(campaignMode); // Find the current mode's index

   const [dropdownOpen, setDropdownOpen] = useState({ campaign: false, principle: false });


   // State
const [selectedLanguage, setSelectedLanguage] = useState("English");
const [languageSelected, setLanguageSelected] = useState(false);

// For the principle
const [principle, setPrinciple] = useState("None");
const [principleSelected, setPrincipleSelected] = useState(false);

// Display logic
const getDisplayLanguage = () => {
  // If the user has *never* manually chosen anything and the default is "English"
  if (!languageSelected && selectedLanguage === "English") {
    return "Language";
  }
  // Once the user *has* manually chosen "English" (or anything else), display it
  return selectedLanguage;
};

const getDisplayPrinciple = () => {
  // If the user has *never* manually chosen anything and the default is "None"
  if (!principleSelected && principle === "None") {
    return "Cialdini Principle";
  }
  return principle;
};

// Handlers
const handleLanguageSelect = (language) => {
  setSelectedLanguage(language);
  setLanguageSelected(true);   // Indicate the user *did* choose something
  toggleDropdown("campaign");
};

const handlePrincipleSelect = (option) => {
  setPrinciple(option);
  setPrincipleSelected(true);
  toggleDropdown("principle");
};


   
   // Toggle dropdown visibility
   const toggleDropdown = (type) => {
      setDropdownOpen((prev) => ({
         campaign: type === "campaign" ? !prev.campaign : false,
         principle: type === "principle" ? !prev.principle : false,
      }));
   };

   const handleOptionSelect = (type, value, event) => {
      event.stopPropagation();
      if (type === "campaign") {
        setSelectedLanguage(value);
        setLanguageSelected(true); // Mark language as selected
      } else if (type === "principle") {
        setPrinciple(value);
        setPrincipleSelected(true); // Mark principle as selected
      }
      setDropdownOpen((prev) => ({
        ...prev,
        [type]: false,
      }));
    };
   useEffect(() => {
      const handleClickOutside = (event) => {
         if (
            !event.target.closest(".custom-dropdown.selectcm") &&
            !event.target.closest(".custom-dropdown.selectcp")
         ) {
            setDropdownOpen({ campaign: false, principle: false });
         }
      };

      document.addEventListener("click", handleClickOutside);
      return () => {
         document.removeEventListener("click", handleClickOutside);
      };
   }, []);

   // 1) Easing function
   const easeInOutQuad = (t) =>
      t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;

   // 2) Reusable fade logic
   const fadeInOutEased = (value, start, end, lockAtPeak = false) => {
      if (value < start) return 0;
      if (value > end && !lockAtPeak) return 0;

      const mid = (start + end) / 2;

      if (lockAtPeak && value >= mid) {
         // Once we hit peak, stay at 0.9
         return 0.9;
      }

      if (value <= mid) {
         const ratio = (value - start) / (mid - start);
         return 0.9 * easeInOutQuad(ratio);
      } else {
         const ratio = (value - mid) / (end - mid);
         return 0.9 * (1 - easeInOutQuad(ratio));
      }
   };

   const [url, setUrl] = useState(null);
   const [mockup, setMockup] = useState({
      url: "https://www.brandname.com",
      headline: "Your Brandname - Enter Your URL Above",
      description: "Enter a Website and instantly Generate PPC search ads. Experience how Easy it is to Launch Ads with AI—no expertise requi",
      display_url: "yourwebsite.com",
      sitelinks: [
         { title: "Try for Yourself", description: "Generate a PPC ad for your website now." },
         { title: "It's Completely Free", description: "See how AI makes ad creation effortless." },
         { title: "Your Page here", description: "Let's customize your journey with us." },
         { title: "Launch in 5 Min", description: "Try for yourself by entering a URL above." },
      ],
   });
   const [mockupLoading, setMockupLoading] = useState(false);
   const [extractLoading, setExtractLoading] = useState(false);
   const COSTS = {
      headline: 50,
      descriptions: 50,
      sitelinks: 50,
      callout: 50,
      snippets: 50,
   };
   const calculateTotalCost = () => {
      const fields = ['headline', 'descriptions', 'sitelinks', 'callout', 'snippets'];
      let cost = 0;

      // Safely iterate through fields and add their cost
      fields.forEach((field) => {
         if (checkedItems[field]) {
            cost += COSTS[field];
         }
      });

      // Safely parse `numberOfAds` or default to 1
      const adsCount = numberOfAds > 0 ? numberOfAds : 1;

      // Final cost calculation
      setTotalCost(cost * adsCount);
   };

   useEffect(() => {
      calculateTotalCost();
   }, []);

   useEffect(() => {
      calculateTotalCost();
   }, [numberOfAds, checkedItems]);

   const generateMockup = async () => {
      if (url) {
         const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
         try {
            setMockupLoading(true)

            const response = await AuthFetch2('campaign-gen/mockupGen', {
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json'
               },
               body: JSON.stringify({
                  url: formattedUrl
               })
            });


            const data = await response.json()
            if (data?.message) {
               setMockupLoading(false)
               return alert(data?.message)
            }

            setMockup(data?.mockup);
            setMockupLoading(false)

            //scroll to mockup gpt
            if (mockupRef.current) {
               mockupRef.current.scrollIntoView({ behavior: 'smooth' });
            }
         } catch (error) {
            console.log(error)
         }

      } else {
         alert('Please fill valid url')
      }

   }

   const mockupRef = useRef(null); //gpt scroll to mockup

   const handleCheckChange = (e) => {
      const { name, checked } = e.target;

      if (name === "all") {
         // Toggle all options
         const newCheckedItems = {
            all: checked,
            headline: checked,
            descriptions: checked,
            sitelinks: checked,
            callout: checked,
            snippets: checked,
         };
         setCheckedItems(newCheckedItems);
      } else {
         // Toggle individual option
         setCheckedItems((prev) => {
            const newCheckedItems = { ...prev, [name]: checked };

            // Update "all" if all other checkboxes are selected
            const allSelected = Object.keys(newCheckedItems)
               .filter((key) => key !== "all")
               .every((key) => newCheckedItems[key]);

            newCheckedItems.all = allSelected;

            return newCheckedItems;
         });
      }

      calculateTotalCost();
   };

   const formatUrl = (url) => {
      // Remove protocol (https:// or http://)
      let formattedUrl = url.replace(/(^\w+:|^)\/\//, '');

      // Remove 'www.' if it exists
      formattedUrl = formattedUrl.replace(/^www\./, '');

      // Remove everything after the first '.'
      formattedUrl = formattedUrl.split('.')[0];

      return formattedUrl;
   };
   const getValidInstruction = (field) => {
      return field.trim() === "" ? null : field;
   };
   const handleExtract = async () => {
      if (!isAuthenticated) return navigate('/login');
      if (!url) return alert('Please fill valid url');
      const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;

      setExtractLoading(true);
      const accessToken = localStorage.getItem('accessToken')
      const { headline, descriptions, sitelinks, callout, snippets } = checkedItems
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/campaign-gen/extract`, {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
         },
         body: JSON.stringify({
            url: formattedUrl, headline, descriptions,
            sitelinks, callout, snippets,
            count: numberOfAds, campaignMode, cialdini: principle === "Cialdini's principle" ? "none" : principle,
            countk: numberOfKeywords,
            specialInstructionsHeadlines: getValidInstruction(
               campaignMode === "Brand name campaign"
                  ? campaignData["Brand name campaign"].menuItems.Headlines
                  : campaignData["Broad search campaign"].menuItems.Headlines
            ),
            specialInstructionsDescriptions: getValidInstruction(
               campaignMode === "Brand name campaign"
                  ? campaignData["Brand name campaign"].menuItems.Descriptions
                  : campaignData["Broad search campaign"].menuItems.Descriptions
            ),
            specialInstructionsSitelinks: getValidInstruction(
               campaignMode === "Brand name campaign"
                  ? campaignData["Brand name campaign"].menuItems.Sitelinks
                  : campaignData["Broad search campaign"].menuItems.Sitelinks
            ),
            specialInstructionsSnippets: getValidInstruction(
               campaignMode === "Brand name campaign"
                  ? campaignData["Brand name campaign"].menuItems.Snippets
                  : campaignData["Broad search campaign"].menuItems.Snippets
            ),
            specialInstructionsCallouts: getValidInstruction(
               campaignMode === "Brand name campaign"
                  ? campaignData["Brand name campaign"].menuItems.Callouts
                  : campaignData["Broad search campaign"].menuItems.Callouts
            ),
            specialInstructionsKeywords: getValidInstruction(
               campaignMode === "Brand name campaign"
                  ? campaignData["Brand name campaign"].menuItems.Keywords
                  : campaignData["Broad search campaign"].menuItems.Keywords
            ),
            language: selectedLanguage
         })
      });

      if (response.ok) {
         const blob = await response.blob();
         const url = window.URL.createObjectURL(blob);

         // Create an anchor element and trigger a download
         const a = document.createElement('a');
         a.href = url;
         a.download = 'campaign_template.xlsx'; // Set the filename for download
         document.body.appendChild(a);
         a.click();

         // Cleanup: Remove the link element and revoke the blob URL
         a.remove();
         window.URL.revokeObjectURL(url);
         setExtractLoading(false)

      } else if (response.status === 401) {
         const { accessToken: newAccessToken } = await refresh();
         const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/campaign-gen/extract`, {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${newAccessToken}`
            },
            body: JSON.stringify({
               url, language: selectedLanguage, headline, descriptions, sitelinks, callout, snippets
            })
         });

         if (res.ok) {
            const blob = await res.blob();
            const url = window.URL.createObjectURL(blob);

            // Create an anchor element and trigger a download
            const a = document.createElement('a');
            a.href = url;
            a.download = 'campaign_template.xlsx'; // Set the filename for download
            document.body.appendChild(a);
            a.click();

            // Cleanup: Remove the link element and revoke the blob URL
            a.remove();
            window.URL.revokeObjectURL(url);
            setExtractLoading(false)
         } else {
            const data = await response.json();
            alert(data.message);
            setExtractLoading(false)
         }
      } else {
         const data = await response.json();
         alert(data?.message)
         setExtractLoading(false)
      }

   }


   useEffect(() => {
      document.title = "Instant Google Ads Generator - Launch PPC ads in Seconds";

      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
         metaDescription.setAttribute("content", "Generate PPC ads instantly using AI. Enter your website to Create, Export and Launch Ads to All Search Engines. Simple, Fast, and Good-Looking.");
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
         metaKeywords.setAttribute("content", "PPC advertising, ai search ads, google ads ai, create search ads");
      }

      // Open Graph tags
      const ogTitle = document.querySelector('meta[property="og:title"]');
      if (ogTitle) {
         ogTitle.setAttribute("content", "Instant Google Ads Generator - Launch PPC ads in Seconds");
      }

      const ogDescription = document.querySelector('meta[property="og:description"]');
      if (ogDescription) {
         ogDescription.setAttribute("content", "Generate PPC ads instantly using AI. Enter your website to Create, Export and Launch Ads to All Search Engines. Simple, Fast, and Good-Looking.");
      }

      const ogImage = document.querySelector('meta[property="og:image"]');
      if (ogImage) {
         ogImage.setAttribute("content", "URL_to_image_for_preview");
      }

      const ogUrl = document.querySelector('meta[property="og:url"]');
      if (ogUrl) {
         ogUrl.setAttribute("content", "https://www.ppcc.ai");
      }

      // Twitter Card tags
      const twitterTitle = document.querySelector('meta[name="twitter:title"]');
      if (twitterTitle) {
         twitterTitle.setAttribute("content", "Instant Google Ads Generator - Launch PPC ads in Seconds");
      }

      const twitterDescription = document.querySelector('meta[name="twitter:description"]');
      if (twitterDescription) {
         twitterDescription.setAttribute("content", "Generate PPC ads instantly using AI. Enter your website to Create, Export and Launch Ads to All Search Engines. Simple, Fast, and Good-Looking.");
      }

      const twitterImage = document.querySelector('meta[name="twitter:image"]');
      if (twitterImage) {
         twitterImage.setAttribute("content", "URL_to_image_for_twitter");
      }

      const twitterCard = document.querySelector('meta[name="twitter:card"]');
      if (twitterCard) {
         twitterCard.setAttribute("content", "summary_large_image");
      }

   }, []);

   const [showAuthenticated, setShowAuthenticated] = useState(isAuthenticated);

   useEffect(() => {
      setShowAuthenticated(isAuthenticated);
   }, [isAuthenticated]); // Runs whenever isAuthenticated changes
   useEffect(() => {
      document.body.style.overflow = showAuthenticated ? "hidden" : "";
  
      const handleNavigation = () => {
          document.body.style.overflow = "";
      };
  
      window.addEventListener("popstate", handleNavigation);
  
      return () => {
          document.body.style.overflow = "";
          window.removeEventListener("popstate", handleNavigation);
      };
  }, [showAuthenticated]);
   
  const [fadeClass, setFadeClass] = useState("fade-in");
  const [showMainContent, setShowMainContent] = useState(false);
  
  const handleExit = () => {
      setFadeClass("fade-out"); // Start fade-out animation
      setTimeout(() => {
          setShowAuthenticated(false); // Hide authenticated section
          setShowMainContent(true);
          setCampaignMode("Brand name campaign"); // Show the main content
          setFadeClass("fade-in"); // Reset fade-in effect
      }, 600); // Matches transition duration
  };
  
  const handleGoBack = (e) => {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => {
          setFadeClass("fade-out");
  
          setTimeout(() => {
              setShowMainContent(false);
              setShowAuthenticated(true);
              setCampaignMode("Broad search campaign");
              setFadeClass("fade-in");
          }, 400);
      }, 500);
  };
   return (
      <>
         <Loader isLoading={mockupLoading || extractLoading} />
         {!isAuthenticated && (
            <div className={fadeClass} style={{ display: showAuthenticated ? "block" : "none" }}>
               <CornerWrapAnimation />
            </div>
         )}
         <div className="main">
            <div className="outer-banner position-relative">
               <section className="banner text-center banner-home">
                  <div className="d-md-block d-none">
                     <BackdropAnimation glowClass={glowClass} delay={1.5} />
                  </div>
                  <div className="d-md-none d-block">
                     <BackdropGlow />
                  </div>

                  <div className="container mobileonly">
                     <AnimatedSection>
                        <div className="top-title mb-md-5 pb-1 mb-2">CREATE A CAMPAIGN</div>
                     </AnimatedSection>
                     <AnimatedSection delay={0.2}>
                        <h1 className="h1 h1mobile">The Fastest Way to Launch Search Ads</h1>
                     </AnimatedSection>
                     <AnimatedSection delay={0.3}>
                        <div className="desc mb-4">
                           <p className="p-18 generatep">Using AI to improve cost-efficiency and accessibility of PPC advertising. Enter your website url below.
                           </p>
                        </div>
                     </AnimatedSection>
                     <HeightAnimatedSection delay={0.4}>
                        <div className="banner-form-sec mt-md-5 mobilehomesec">
                           <form className="d-md-flex justify-content-center align-items-center generateform" onSubmit={(e) => {
                              e.preventDefault();  // Prevent form from reloading the page
                              generateMockup();    // Trigger the button action
                           }}>
                              <div className="form-group me-0 me-md-3 ">
                                 <input
                                    placeholder="https://www.yourwebsite.com"
                                    className="form-control"
                                    name="site_url"
                                    value={url}
                                    onChange={(e) => { setUrl(e.target.value) }}
                                    autocomplete="url"

                                 />
                              </div>
                              <div className="submit-btn mt-md-0 mt-3 ">
                                 <button type="button" className="btn " onClick={generateMockup}>
                                    Generate ad
                                    <svg
                                       xmlns="http://www.w3.org/2000/svg"
                                       viewBox="0 0 256 256"
                                       focusable="false"
                                       color="rgb(255, 255, 255)"
                                    >
                                       <g color="rgb(255, 255, 255)" weight="bold">
                                          <path d="M224.49,136.49l-72,72a12,12,0,0,1-17-17L187,140H40a12,12,0,0,1,0-24H187L135.51,64.48a12,12,0,0,1,17-17l72,72A12,12,0,0,1,224.49,136.49Z"></path>
                                       </g>
                                    </svg>
                                 </button>
                              </div>
                           </form>

                        </div>
                        {isAuthenticated && (
                           <div className="signedinundergenerate"></div>
                        )}
                     </HeightAnimatedSection>

                  </div>
                  <div className="container desktoponly">
                     <motion.div style={{ scale: smoothScale, overflow: "visible", position: "relative" }}>
                        <div style={{ overflow: "visible", position: "relative" }}> {/* Additional layer to isolate scaling */}
                           <AnimatedSection delay={0.2}>
                              <div className="top-title mb-md-5 pb-1 mb-2">CREATE A CAMPAIGN</div>
                           </AnimatedSection>
                           <span className={`exitauthenticated ${fadeClass}`} onClick={handleExit} style={{ display: showAuthenticated ? "block" : "none" }}>➔</span>
                           <AnimatedSection delay={0.4}>
                              <h1 className="h1">The Fastest Way to Launch Search Ads</h1>
                           </AnimatedSection>
                           <AnimatedSection delay={0.6}>
                              <div className="desc mb-4">
                                 <p class="p-18 generatep">Using AI to improve cost-efficiency and accessibility of PPC advertising. Enter your website url below.</p>
                              </div>
                           </AnimatedSection>

                           <div className="banner-form-sec mt-md-5">
                              <HeightAnimatedSection delay={0.9}>
                                 <form className="d-md-flex justify-content-center align-items-center generateform" onSubmit={(e) => { e.preventDefault(); generateMockup(); }}>
                                    <div className="form-group me-0 me-md-3 ">
                                       <input
                                          placeholder="https://www.yourwebsite.com"
                                          className="form-control"
                                          name="site_url"
                                          value={url}
                                          onChange={(e) => { setUrl(e.target.value) }}
                                       />
                                    </div>

                                    <div className="submit-btn mt-md-0 mt-3 ">
                                       <button
                                          type="button"
                                          className="btn"
                                          onClick={showAuthenticated ? handleExtract : generateMockup}
                                       >
                                          <span className={`btn-text`}>
                                             {showAuthenticated ? "Download all" : "Generate ad"}
                                          </span>
                                          <svg
                                             xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 256 256"
                                             focusable="false"
                                             color="rgb(255, 255, 255)"
                                          >
                                             <g color="rgb(255, 255, 255)" weight="bold">
                                                <path d="M224.49,136.49l-72,72a12,12,0,0,1-17-17L187,140H40a12,12,0,0,1,0-24H187L135.51,64.48a12,12,0,0,1,17-17l72,72A12,12,0,0,1,224.49,136.49Z"></path>
                                             </g>
                                          </svg>
                                       </button>
                                    </div>
                                 </form>
                              </HeightAnimatedSection>
                              {isAuthenticated && (
                                 <div>
                                    <AnimatedSection delay={0.6}>
                                       <div className="or-sec mt-5 settingslines">SETTINGS</div>
                                    </AnimatedSection>

                                    <AnimatedSection delay={0.5}>
                                       <div className="settings-wrapper">
                                          {/* ====== USER EXITED - SCROLL ENABLED ====== */}
                                          <div className="settingsitems settings1">
                                             <div className={`signup-text ${fadeClass}`}>
                                                {showAuthenticated ? (
                                                   ""
                                                ) : (
                                                   <>
                                                       Customize settings and download directly. <a class="linknostyle" href="#" onClick={handleGoBack}>Click here</a>.
                                                   </>
                                                )}
                                             </div>

                                             {showAuthenticated ? (
                                                <>
                                                {/* ====== Settings ====== */}
                                                   <div class="newsettingsstuff">
                                                      <div class="twohorizontally">
                                                         <div className="thirdsettingsline amountscc">
                                                            {/* Amount of Ads Input */}
                                                            <div className="label-sc1 label-sc1c align-items-center" style={{ fontSize: "14px"}}>
                                                               <label htmlFor="number_of_ads" className="me-2 amountofadsc2">Amount of ads</label>

                                                               <input placeholder="Enter number" className="amountofads realamount amountofadsc" type="number" id="number_of_ads" name="number_of_ads"
                                                                  value={numberOfAds}
                                                                  onChange={(e) => setNumberOfAds(Math.max(1, Number(e.target.value) || 1))}
                                                                  min="1" max="5"
                                                               />
                                                               <div class="controlinputc"><span className="minusc minus-sign minus-sign-amount" onMouseDown={(e) => e.preventDefault()} onClick={() => setNumberOfAds((prev) => Math.max(1, prev - 1))}>-</span>
                                                                  <span className="plus-sign plussignc" onMouseDown={(e) => e.preventDefault()} onClick={() => setNumberOfAds((prev) => Math.min(5, prev + 1))}>+</span></div>
                                                            </div>

                                                            {/* Amount of Keywords Input */}
                                                            <div className="label-keywords label-sc1 label-sc1c align-items-center" style={{ fontSize: "14px", marginTop: "10px" }}>
                                                               <label htmlFor="number_of_keywords" className="me-2 amountofkeywordsc">Amount of keywords</label>

                                                               <input placeholder="Enter number" className="amountofads amountofadsc" type="number" id="number_of_keywords" name="number_of_keywords"
                                                                  value={numberOfKeywords}
                                                                  onChange={(e) => setNumberOfKeywords(Math.max(5, Number(e.target.value) || 5))}
                                                                  min="5" max="20"
                                                               />
                                                               <div class="controlinputc2"><span className=" plussignc plus-sign" onMouseDown={(e) => e.preventDefault()} onClick={() => setNumberOfKeywords((prev) => Math.min(20, prev + 5))}>+</span>
                                                                  <span className="minusc minus-sign mmam" onMouseDown={(e) => e.preventDefault()} onClick={() => setNumberOfKeywords((prev) => Math.max(5, prev - 5))}>-</span>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div class="txtar">
                                                         <div className="dynamic-textarea-wrapper">
                                                            {/* Navigation Menu for Special Instructions */}
                                                            <div className="special-instructions-menu">
                                                               {["Headlines", "Descriptions", "Sitelinks", "Callouts", "Snippets", "Keywords"].map((item) => (
                                                                  <button
                                                                     key={item}
                                                                     className={`menu-btn ${selectedMenu === item ? "selected" : ""}`}
                                                                     onClick={() => setSelectedMenu(item)}
                                                                  >
                                                                     {item.charAt(0)} {/* Display only first letter for compact UI */}
                                                                  </button>
                                                               ))}
                                                            </div>

                                                            {/* Textarea for Special Instructions */}
                                                            <div className="textarea-container">
                                                               <span className="selected-menu-name">{selectedMenu}</span>
                                                               <div className="tooltip-container tooltip-container1">
                                                                  <span className="question-mark question-mark1">?</span>
                                                                  <div className="tooltip-text tooltip-text1">
                                                                     <span className="tool-tip-title">Tips:</span>
                                                                     <ul>
                                                                        <li>- Maintain the prompt structure</li>
                                                                        <li>- Avoid removal of character limits</li>
                                                                        <li>- Empty to exclude from download</li>
                                                                     </ul>
                                                                  </div>
                                                               </div>
                                                               <textarea
                                                                  className="form-control custominform dynamic-textarea"
                                                                  onFocus={() => setShowCustomInstructions(true)}
                                                                  rows="4"
                                                                  value={campaignData[campaignMode].menuItems[selectedMenu] || ""}
                                                                  onChange={handleInstructionsChange}
                                                                  placeholder={`Leave empty to skip ${selectedMenu}`}
                                                               />
                                                               <div className="charactercountert">
                                                                  {campaignData[campaignMode].menuItems[selectedMenu]?.length || 0}/{maxInstructionsLength}
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div class="twohorizontally">
                                                         <div className="settingsselect settingsselectc">
                                                            {/* Language Dropdown */}
                                                            <div className="custom-dropdown selectcm selectcmc" onClick={() => toggleDropdown("campaign")}>
                                                               <div className="custom-dropdown-selected">
                                                                  {getDisplayLanguage()}
                                                                  <svg className="custom-dropdown-chevron" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                                                                     <path fill="white" d="M7 10l5 5 5-5z" />
                                                                  </svg>
                                                               </div>
                                                               {dropdownOpen.campaign && (
                                                                  <ul className="custom-dropdown-options custom-dropdown-optionsc">
                                                                     {["English", "Spanish", "French", "Dutch", "German"].map((language) => (
                                                                        <li key={language} className="custom-dropdown-option custom-dropdown-optionc" onClick={(e) => handleOptionSelect("campaign", language, e)}>
                                                                           {language}
                                                                        </li>
                                                                     ))}
                                                                  </ul>
                                                               )}
                                                            </div>

                                                            {/* Cialdini's Principle Dropdown */}
                                                            <div className="custom-dropdown selectcp selectcpc" onClick={() => toggleDropdown("principle")}>
                                                               <div className="custom-dropdown-selected">
                                                               {getDisplayPrinciple()}
                                                                  <svg className="custom-dropdown-chevron" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                                                                     <path fill="white" d="M7 10l5 5 5-5z" />
                                                                  </svg>
                                                               </div>
                                                               {dropdownOpen.principle && (
                                                                  <ul className="custom-dropdown-options custom-dropdown-optionsc">
                                                                     {["Reciprocity", "Consistency", "Social Proof", "Authority", "Liking", "Scarcity", "None"].map((option) => (
                                                                        <li key={option} className="custom-dropdown-option" onClick={(e) => handleOptionSelect("principle", option, e)}>
                                                                           {option}
                                                                        </li>
                                                                     ))}
                                                                  </ul>
                                                               )}
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </>
                                             ) : (
                                                <>
                                                  <div
                                       className="btm-btn-sc d-sm-flex justify-content-center mx-auto btnswith" style={{ width: "fit-content", }}>
                                       <button onClick={() => { signUpWithGoogle(); }} className="btn btn-border mx-1 g-f-button nohovereffect">
                                          <img src="/images/google.png" alt="" />Import to Google</button>
                                       <button onClick={() => { signUpWithMicrosoft(); }} className="btn btn-border mx-1 g-f-button nohovereffect">
                                          <img src="/images/microsoft.png" alt="" />Import to Bing</button>
                                    </div>
                                                </>
                                             )}

                                          </div>
                                       </div>

                                    </AnimatedSection>

                                 </div>)}
                              <div className="modal-body"></div>
                           </div>
                        </div>
                     </motion.div>
                     <motion.div style={{ scale: smoothScale }}>
                        <div className="d-md-block d-none pb-3">
                           {!isAuthenticated && (
                              <><AnimatedSection delay={0.8}>
                                 <div className="or-sec mt-5">OR</div>
                              </AnimatedSection>
                                 <AnimatedSection delay={0.7}>
                                    <div className="signup-text">
                                       Sign up for free with Google or Microsoft.
                                    </div>
                                 </AnimatedSection>
                                 <AnimatedSection delay={0.8}>
                                    <div
                                       className="btm-btn-sc d-sm-flex justify-content-center mx-auto btnswith" style={{ width: "fit-content", }}>
                                       <button onClick={() => { signUpWithGoogle(); }} className="btn btn-border mx-1 g-f-button nohovereffect">
                                          <img src="/images/google.png" alt="" />Continue With Google</button>
                                       <button onClick={() => { signUpWithMicrosoft(); }} className="btn btn-border mx-1 g-f-button nohovereffect">
                                          <img src="/images/microsoft.png" alt="" />Continue With Microsoft</button>
                                    </div>
                                 </AnimatedSection></>)}
                           {isAuthenticated && (<div className="signedinundergenerate"></div>)}
                        </div>
                     </motion.div>
                  </div>
               </section>

               {mockup !== null && (
                  <section ref={mockupRef} className="frame-image-sc space-mr">
                     {/* MOCKUP  */}
                     <AnimatedSection>
                        <div className="container">
                           <div className="wrapper">
                              <div className="desktop_mockup nomobile">
                                 <div className="google_interface google_interface_desktop">
                                    <div className="header_area">
                                       <div className="header_ser">
                                          <div className="serach_wrapper">
                                             <div className="google_logo">
                                                <img src="images/Google_2015_logo2.png" alt="" height="40px" class="googlelogoimg" />
                                             </div>
                                             <div className="google_serach">
                                                <div className="ser">
                                                   <input
                                                      type="text"
                                                      className="google_serach_input p-0"
                                                      value={mockup.url ? formatUrl(mockup.url) : "brandname"}
                                                   />
                                                   <div className="google-search-close me-2">
                                                      <svg
                                                         focusable="false"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 24 24"
                                                      >
                                                         <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                                      </svg>
                                                   </div>
                                                </div>
                                                <div className="end_area">
                                                   <button href="#" className="goog_btn mic">
                                                      <img src="images/Google_mic.svg" alt="" />
                                                   </button>
                                                   <button href="#" className=" goog_btn lens">
                                                      <img src="images/7123028_lens_google_icon.svg" alt="" />
                                                   </button>
                                                   <button href="#" className="goog_btn serch_ico">
                                                      <img src="images/Vector_search_icon.svg.png" alt="" />
                                                   </button>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="right ">
                                             <div className="google_apps">
                                                <img src="images/186401_grid_icon.png" alt="" width="25px" />
                                             </div>
                                             <div className="gmail">
                                                <img src="images/smalllogo.png" alt="" />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="tabs">
                                          <div className="left_tabs">
                                             <button href="#" className="tab_btn active">All</button>
                                             <button href="#" className="tab_btn">Images</button>
                                             <button href="#" className="tab_btn">Videos</button>
                                             <button href="#" className="tab_btn">News</button>
                                             <button href="#" className="tab_btn">Shopping</button>
                                             <button href="#" className="tab_btn">Maps</button>
                                             <button href="#" className="tab_btn">Books</button>
                                             <button href="#" className="tab_btn d-none">
                                                <span>
                                                   <i className="bx bx-dots-vertical-rounded"></i>
                                                </span>
                                                More
                                             </button>
                                          </div>
                                          <div className="right_tabs d-none">
                                             <button className="tab_btn ">Tool</button>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="capsul_tab">
                                       <button className="capsul">Free</button>
                                       <button className="capsul">Reviews</button>
                                       <button className="capsul">Open Now</button>
                                       <button className="capsul">Clips</button>
                                       <button className="capsul">Pricing</button>
                                       <button className="capsul">Alternative</button>
                                    </div>
                                    <div className="google_serach_result">
                                       <div className="sponsored-text2">
                                          <span>Sponsored</span>
                                       </div>
                                       <div className="serach_item">
                                          <div className="brand_logo_img">
                                             <img src="images/smalllogo.png" alt="" />
                                          </div>
                                          <div className="brand_info">
                                             <div className="barnd_name">
                                                <h6 className="m-0 ">
                                                   {mockup?.display_url
                                                      ? mockup?.display_url
                                                      : "ppcc.ai"}
                                                </h6>
                                                <div className="link">
                                                   <p className="website_link">
                                                      {mockup?.url
                                                         ? mockup?.url
                                                         : "# "}
                                                   </p>
                                                   <a href="#" className="modal_dot">
                                                      <i className="bx bx-dots-vertical-rounded"></i>
                                                   </a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="serach_result_main">
                                          <a
                                             href="#"
                                             className=""

                                             onclick="return false;"
                                          >
                                             <h1>
                                                {mockup?.headline
                                                   ? mockup?.headline
                                                   : " PPCC - Generate Google Ads - Try for Free "}
                                             </h1>
                                          </a>
                                          <p className="info">
                                             {mockup?.description
                                                ? mockup?.description
                                                : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                                          </p>
                                       </div>
                                       {mockup?.sitelinks?.length >= 0
                                          ? mockup?.sitelinks?.map((item, index) => (
                                             <div className="Ktlw8e" key={index}>
                                                <div className="iCzAIb">
                                                   <div className="d-flx mock-site-link">
                                                      <a href="#" className="" onclick="return false;">{item.title}</a>
                                                      <div className="icon d-none-icon">
                                                         <i className="bx bx-chevron-right"></i>
                                                      </div>
                                                   </div>
                                                   <p className="info m-0">{item.description}</p>
                                                </div>
                                             </div>
                                          ))
                                          : Mocksite?.map((item, index) => (
                                             <div className="Ktlw8e" key={index}>
                                                <div className="iCzAIb">
                                                   <div className="d-flx mock-site-link">
                                                      <a className="" onclick="return false;">{item.site_text}</a>
                                                      <div className="icon d-none-icon">
                                                         <i className="bx bx-chevron-right"></i>
                                                      </div>
                                                   </div>
                                                   <p className="info m-0">{item.sitePara}...</p>
                                                </div>
                                             </div>
                                          ))}
                                       {/* SECOND SEARCH RESULT DESKTOP */}
                                       <div className="serach_item serach_item2">
                                          <div className="brand_logo_img">
                                             <img src="images/smalllogo.png" alt="" />
                                          </div>
                                          <div className="brand_info">
                                             <div className="barnd_name">
                                                <h6 className="m-0 ">
                                                   {mockup?.display_url
                                                      ? mockup?.display_url
                                                      : "ppcc.ai"}
                                                </h6>
                                                <div className="link">
                                                   <p className="website_link">
                                                      {mockup?.url
                                                         ? mockup?.url
                                                         : "#"}
                                                   </p>
                                                   <a href="#" className="modal_dot">
                                                      <i className="bx bx-dots-vertical-rounded"></i>
                                                   </a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="serach_result_main">
                                          <a
                                             href="https://www.ppcc.ai/#"
                                             className=""

                                             onclick="return false;"
                                          >
                                             <h1>
                                                {mockup?.headline
                                                   ? mockup?.headline
                                                   : " PPCC - Generate Google Ads - Try for Free "}
                                             </h1>
                                          </a>
                                          {/* <span className="brand_name_highlisght">Munch</span> */}
                                          <p className="info">
                                             {mockup?.description
                                                ? mockup?.description
                                                : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                                          </p>
                                          <div class="snippetdiv"><a href="#"><span class="snippet">Your</span></a><a href="#"><span class="snippet">Callouts</span></a><a href="#"><span class="snippet">Here</span></a><a href="#"><span class="snippet">Try for Free</span></a></div>
                                       </div>

                                    </div>
                                 </div>
                              </div>
                              <div className="desktop_mockup mobile_mockup">
                                 <div className="google_interface google_interface_mobile">
                                    <div className="header_area_mobile">
                                       <div className="header_ser_mobile">
                                          <div className="serach_wapper">
                                             <div className="google_logo_mobile">
                                                <img src="images/Google_2015_logo2.png" alt="" height="40px" />
                                             </div>
                                             <div className="gmail gmailmobile">
                                                <img src="images/smalllogo.png" alt="" />
                                             </div>
                                             <div className="google_serach google_serach_mobile">
                                                <div className="ser">

                                                   <button href="#" className="goog_btn serch_ico serch_icomobile">
                                                      <img src="images/Vector_search_icon.svg.png" alt="" />
                                                   </button>

                                                   <input
                                                      type="text"
                                                      className="google_serach_input google_serach_input_mobile p-0"
                                                      value={mockup.url ? formatUrl(mockup.url) : "brandname"}
                                                   />
                                                   <div className="google-search-close me-2">
                                                      <svg
                                                         focusable="false"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 24 24"
                                                      >
                                                         <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                                      </svg>
                                                   </div>
                                                </div>
                                                <div className="end_area">
                                                   <button href="#" className="goog_btn mic_mobile">
                                                      <img src="images/Google_mic.svg" alt="" />
                                                   </button>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="right ">
                                             {/* <div className="search_labs">
                                             <img src="images/serachlabs.png" alt="" width="25px" />
                                          </div> */}

                                          </div>
                                       </div>
                                       <div className="tabsmobile">
                                          <div className="left_tabs_mobile">
                                             <button href="#" className="tab_btn_mobile active">All</button>
                                             <button href="#" className="tab_btn_mobile">Images</button>
                                             <button href="#" className="tab_btn_mobile">Videos</button>
                                             <button href="#" className="tab_btn_mobile">News</button>
                                             <button href="#" className="tab_btn_mobile">Shopping</button>
                                             <button href="#" className="tab_btn_mobile">Maps</button>
                                             <button href="#" className="tab_btn_mobile">Books</button>
                                             <button href="#" className="tab_btn_mobile d-none">
                                                <span>
                                                   <i className="bx bx-dots-vertical-rounded"></i>
                                                </span>
                                                More
                                             </button>
                                          </div>
                                          <div className="right_tabs d-none">
                                             <button className="tab_btn ">Tool</button>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="capsul_tab_mobile">
                                       <button href="#" className="capsul_mobile">Free</button>
                                       <button href="#" className="capsul_mobile">Reviews</button>
                                       <button href="#" className="capsul_mobile">Open Now</button>
                                       <button href="#" className="capsul_mobile">Clips</button>
                                       <button href="#" className="capsul_mobile">Pricing</button>
                                    </div>
                                    <div className="google_serach_result_mobile">
                                       <div className="sponsored-text2_mobile">
                                          <span>Sponsored</span>
                                       </div>
                                       <div className="serach_item">
                                          <div className="brand_logo_img brand_logo_img_mobile">
                                             <img src="images/smalllogo.png" alt="" />
                                          </div>
                                          <div className="brand_info">
                                             <div className="barnd_name_mobile">
                                                <h6 className="m-0 ">
                                                   {mockup?.display_url
                                                      ? mockup?.display_url
                                                      : "yourwebsite.com"}
                                                </h6>
                                                <div className="link">
                                                   <p className="website_link_mobile">
                                                      {mockup?.url
                                                         ? mockup?.url
                                                         : "#"}
                                                   </p>
                                                   <a href="#" className="modal_dot_mobile">
                                                      <i className="bx bx-dots-vertical-rounded"></i>
                                                   </a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="serach_result_main_mobile">
                                          <a
                                             href="#"
                                             className=""

                                             onclick="return false;"
                                          >
                                             <h1>
                                                {mockup?.headline
                                                   ? mockup?.headline
                                                   : " PPCC - Generate Google Ads - Try for Free "}
                                             </h1>
                                          </a>
                                          {/* <span className="brand_name_highlisght">Munch</span> */}
                                          <p className="info_mobile">
                                             {mockup?.description
                                                ? mockup?.description
                                                : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                                          </p>
                                       </div>
                                       {mockup?.sitelinks?.length >= 0
                                          ? mockup?.sitelinks?.map((item, index) => (
                                             <div className={`Ktlw8e_mobile ${index === Mocksite.length - 1 ? 'sitelinklast' : ''}`}>
                                                <div className="iCzAIb_mobile">
                                                   <div className="d-flx mock-site-link">
                                                      <a href="#" className="" onclick="return false;">{item.title}</a><span class="arrowsitelink">›</span>
                                                      <div className="icon d-none-icon">
                                                         <i className="bx bx-chevron-right"></i>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          ))
                                          : Mocksite?.map((item, index) => (
                                             <div className={`Ktlw8e_mobile ${index === Mocksite.length - 1 ? 'sitelinklast' : ''}`}>
                                                <div className="iCzAIb_mobile">
                                                   <div className="d-flx mock-site-link">
                                                      <a className="" onclick="return false;">{item.site_text}</a>
                                                      <div className="icon d-none-icon">
                                                         <i className="bx bx-chevron-right"></i>
                                                      </div>
                                                   </div>
                                                   <p className="info m-0">{item.sitePara}</p>
                                                </div>
                                             </div>
                                          ))}
                                    </div>


                                    {/* SECOND SEARCH RESULT */}
                                    <div className="google_serach_result_mobile google_serach_result_mobile2">

                                       <div className="serach_item">
                                          <div className="brand_logo_img brand_logo_img_mobile">
                                             <img src="images/smalllogo.png" alt="" />
                                          </div>
                                          <div className="brand_info">
                                             <div className="barnd_name_mobile">
                                                <h6 className="m-0 ">
                                                   {mockup?.display_url
                                                      ? mockup?.display_url
                                                      : "ppcc.ai"}
                                                </h6>
                                                <div className="link">
                                                   <p className="website_link_mobile">
                                                      {mockup?.url
                                                         ? mockup?.url
                                                         : "# "}
                                                   </p>
                                                   <a href="#" className="modal_dot_mobile">
                                                      <i className="bx bx-dots-vertical-rounded"></i>
                                                   </a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="serach_result_main_mobile">
                                          <a
                                             href="#"
                                             className=""

                                             onclick="return false;"
                                          >
                                             <h1>
                                                {mockup?.headline
                                                   ? mockup?.headline
                                                   : " PPCC AI - Generate Google Ads - Try for Free "}
                                             </h1>
                                          </a>
                                          {/* <span className="brand_name_highlisght">Munch</span> */}
                                          <p className="info_mobile info_mobile2">
                                             {mockup?.description
                                                ? mockup?.description
                                                : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                                          </p>
                                          <div class="snippetdivmobile"><a href="#"><span class="snippetmobile">Your</span></a><a href="#"><span class="snippetmobile">Callouts</span></a><a href="#"><span class="snippetmobile">Here</span></a><a href="#"><span class="snippetmobile">Try and See for Free</span></a></div>
                                       </div>


                                    </div>



                                 </div>
                              </div>
                           </div>
                        </div>
                     </AnimatedSection>
                  </section>
               )}
            </div>
            {/* SECTION UNDER MOCKUP  */}
            <section className="extract-sc">
               <div className="container">
                  <div className="row align-items-center justify-content-between extract-sc-in">
                     <div className="col-md-7 order-md-1 order-2 lft">
                        <AnimatedSection>
                           <h2 className="sec-title extracttile">Download <span class="custom-arrow">&rarr;</span> Import <span class="custom-arrow">&rarr;</span> Publish</h2>
                           <p className="my-3 extractp">
                              Export your generated ads to a Google sheet with a few clicks and directly upload it into the Google ads Editor.
                           </p>
                           <div className="btn-sc d-md-flex align-items-center nohovereffect extractsett">
                              <div>
                                 <button onClick={handleExtract} className="btn btn-without-effect btn-sq me-3 extractbtn">
                                    Download <i class="csv-icon" >CSV</i>
                                 </button>
                              </div>

                              {isAuthenticated ? (
                                 <div class="extractcost">
                                    <p class="p-16 moresettingstext nextinstead" style={{ marginLeft: "15px" }}>
                                       Estimated cost: <span style={{ color: "white" }}>{totalCost || 250} credits</span>
                                       <div class="tooltip-container">
                                          <span class="question-mark">?</span>
                                          <div class="tooltip-text">Credits are the in-app currency you spend to generate, refine, and download ad campains.</div>
                                       </div>
                                    </p>
                                 </div>
                              ) : (
                                 <div className="outer-more-settings">
                                    <span className="p-16 moresettingstxt nextinstead" style={{ marginLeft: "10px", scale }}>
                                       & Launch in 60 sec
                                    </span>
                                 </div>
                              )}
                           </div>
                        </AnimatedSection>
                     </div>
                     <div className="col-md-5 order-md-2 order-1 rgt mt-md-0 mt-5 ext-camp-data-image">
                        <AnimatedSection>
                           <img src="/images/extract.png" alt="" className="w-100 h-100 object-fit-cover" />
                        </AnimatedSection>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </>
   );
}
export default Homepage;
