import React, { useEffect } from "react";
import './App.css';
import './AppResponsive.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/HomePage";
import Layout from "./Components/Layout";
import ScrollToTop from './Components/ScrollToTop';
import Resource from "./Pages/resources";
import Login from "./Pages/login";
import Pricing from "./Pages/pricing";
import Contact from "./Pages/Contact";
import SignUp from "./Pages/signup";
import Headlines from "./Pages/headlines";
import Descriptions from "./Pages/descriptions"; // Import the new page
import StructuredSnippets from "./Pages/structured-snippets";
import Callouts from "./Pages/callouts";
import Sitelinks from "./Pages/sitelinks";
import Recharge from "./Pages/recharge";



function App() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("accessToken");
    const refreshToken = urlParams.get("refreshToken");
    if (accessToken && refreshToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      urlParams.delete("accessToken");
      urlParams.delete("refreshToken");

      // Update the URL without reloading the page
const newUrl = window.location.pathname + '?' + urlParams.toString();
window.location.href = newUrl;

    }
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route path="resources" element={<Resource />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="contact" element={<Contact />} />
          <Route path="headlines" element={<Headlines />} />
          <Route path="descriptions" element={<Descriptions />} />
          <Route path="sitelinks" element={<Sitelinks />} />
          <Route path="callouts" element={<Callouts />} />
          <Route path="structured-snippets" element={<StructuredSnippets />} />
          <Route path="recharge" element={<Recharge />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<SignUp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
