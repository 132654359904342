import React from "react";
import AnimatedSection from "./AnimatedSection";

const accordionData = [
   {
      title: "How is credit cost calculated?",
      description:
         "For each component in your downloaded campaign (eg. Headlines, Descriptions, Sitelinks, Callouts, Snippets, Keywords) 50 credits are deducted.\n\nThe amount of ads field multiplies the cost of ad components (eg. Headlines, Descriptions, Sitelinks, Snippets)\n\nThe amount of keywords field multiplies the 50 credit cost of Keyword generation.",
   },
   {
      title: "Credit management secret",
      description:
         "Empty specific special instruction fields to exclude components you do not need from your campaign to save credits.",
   },
];

const CreditsFAQ = () => {
   return (
      <AnimatedSection>
         <section className="faq-sc text-center space-mr-m mb-5">
            <div className="container">
               <div className="row justify-content-center">
                  <div className="col-md-7">
                     <div className="h2 subtitlepricing titleminmargintop">Everything about Credits</div>
                     <AnimatedSection>
                        <p class="p-18 faqp">Credits are the currency used to generate PPC campaigns.</p>
                     </AnimatedSection>
                     <AnimatedSection>
                        <div class="accordion accordion-flush text-start mt-5" id="accordionFlushExample">
                        {accordionData?.map((item, index) => {
   return (
      <div className="accordion-item" key={index}>
         <h2 className="accordion-header" id={`faq_heading${index}`}>
            <button
               className="accordion-button collapsed"
               type="button"
               data-bs-toggle="collapse"
               data-bs-target={`#faq${index}`}
               aria-expanded="false"
               aria-controls={`faq${index}`}
            >
               {item.title}
            </button>
         </h2>
         <div
            id={`faq${index}`}
            className="accordion-collapse collapse"
            aria-labelledby={`faq_heading${index}`}
            data-bs-parent="#accordionFlushExample"
         >
            <div className="accordion-body" style={{ whiteSpace: "pre-line" }}>
               {item.description}
            </div>
         </div>
      </div>
   );
})}

                        </div>
                     </AnimatedSection>
                  </div>
               </div>
            </div>
         </section>
      </AnimatedSection>
   );
};

export default CreditsFAQ;
