// import Hamburger from "hamburger-react";
// import React, { useEffect, useState } from "react";
// import { Link, useLocation } from "react-router-dom";

// const Header = () => {
//    const [isActive, setIsActive] = useState(false);
//    const location = useLocation();
//    useEffect(() => {
//       setIsActive(false); // Reset isActive to false when the location changes
//    }, [location]);

//    const handleToggle = () => {
//       setIsActive(!isActive);
//    };
//    return (
//       <header className={`header py-4 ${isActive ? "active" : ""} header-sticky`}>
//          <div className="container new">
//             <div className="row align-items-center">
//                <div className="col-8 col-md-4">
//                   <div className="logo">
//                      <Link to="/">
//                         {" "}
//                         <img src="/images/logo.svg" alt="image" />
//                      </Link>
//                   </div>
//                </div>
//                <div className="col-4 d-md-none d-block">
//                   <div className="hamberger-menu " onClick={handleToggle}>
//                      <Hamburger toggled={isActive} size={20} toggle={setIsActive} />
//                   </div>
//                </div>
//                <div className=" col-md-4 active-mb">
//                   <nav class="header-nav">
//                      <ul className="d-md-flex justify-content-center mb-0">
//                         <li>
//                            <Link to="/">Generate</Link>
//                         </li>
//                         <li>
//                            <Link to="/Resource">Resources</Link>
//                         </li>
//                         <li>
//                            <Link to="/Pricing">Pricing</Link>
//                         </li>
//                      </ul>
//                   </nav>
//                </div>
//                <div className="col-md-4 active-mb mt-md-0 mt-4">
//                   <div className="btn-sec d-md-flex justify-content-md-end">
//                      <Link to={"/login"} className="btn btn-without-hover btn-t me-3">
//                         Login
//                      </Link>
//                      <Link to="" className="btn">
//                         Sign Up
//                      </Link>
//                   </div>
//                </div>
//             </div>
//          </div>
//       </header>
//    );
// };

// export default Header;

import Hamburger from "hamburger-react";
import React, { useEffect, useState, useRef } from "react";
import { BsStars } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useUser } from "../contexts/userContext";
import { LiaCoinsSolid } from "react-icons/lia";

const Header = () => {
   const [isActive, setIsActive] = useState(false);
   const [isSticky, setIsSticky] = useState(false);
   const isAuthenticated = useAuth();
   const {user, setUser} = useUser() 
   const location = useLocation();
   const navigate = useNavigate();

   useEffect(() => {
      setIsActive(false); // Reset isActive to false when the location changes
   }, [location]);

   useEffect(() => {
      const handleScroll = () => {
         if (window.scrollY > 0) { // Change the value to control when the header becomes sticky
            setIsSticky(true);
         } else {
            setIsSticky(false);
         }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
         window.removeEventListener("scroll", handleScroll);
      };
   }, []);

   const signOut = () => {
      localStorage.clear();
      window.location.reload();
   }
   
   return (
      <header
         className={`header py-4 ${isActive ? "active" : ""} ${location?.pathname !== "/" ? "header-sticky" : ""} ${
            isSticky ? "is-sticky" : ""
         }`}
      >
         <div className="container new mobileonly">
            <div className="row align-items-center">
               <div className="col-8 col-lg-4">
                  <div className="logo">
                     <Link to="/">
                        <img src="/images/logo.svg" alt="image" />
                     </Link>
                  </div>
               </div>
               <div className="col-4 d-lg-none d-flex align-items-center justify-content-end">
                  <div className="hamberger-menu">
                     <Hamburger toggled={isActive} size={20} toggle={setIsActive} />
                  </div>
               </div>
               <div className="header-mobile-nav col-lg-8 d-lg-flex justify-content-between align-items-center">
                  <div className="">
                     <nav className="header-nav">
                        <ul className="d-lg-flex justify-content-center mb-0">
                           <li>
                              <Link to="/">Generate</Link>
                           </li>
                           <li>
                              <Link to="/pricing">{isAuthenticated ? "Upgrade" : "Pricing"}</Link>
                           </li>
                        </ul>
                     </nav>
                  </div>
                  <div className="mt-md-0 mt-4">
                     {!isAuthenticated  ? 
                     <div className="btn-sec d-lg-flex justify-content-md-end">
                        <Link to={"/login"} className="btn btn-without-hover btn-t me-3 d-lg-block d-none">
                           Login
                        </Link>
                        <Link to={"/login"} className="btn btn-t me-3 d-lg-none d-flex gap-1 align-items-center justify-content-center">
                           <BsStars />
                           Login
                        </Link>

                        <Link to="/signup" className="btn">
                           Sign Up
                        </Link>
                     </div> : 
                     <div style={{display: 'flex', alignItems: 'center', gap: '0px'}}>
   <p style={{ margin: 0, cursor: "pointer" }} class="creditsleft" onClick={() => navigate("/recharge")}>Credits left: {user?.credits?.toLocaleString('de-DE')}<br/></p>
   <button className="btn" onClick={signOut}>Sign Out</button>
</div>}
                  </div>
               </div>
            </div>
         </div>

   <div className="container new nomobile">
<div className="d-flex align-items-center p-3" style={{ position: 'relative' }}>
   {/* Logo Section (Left-aligned) */}
   <div className="logo" style={{ position: 'absolute', left: '0', width:'104px'}}>
      <Link to="/">
         <img src="/images/logo.svg" alt="image" />
      </Link>
   </div>

   {/* Middle Navigation Links (Center-aligned in the viewport) */}
   <nav className="header-nav" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
      <ul className="d-flex mb-0" style={{ gap: "0px", listStyle: 'none', padding: 0 }}>
         <li>
            <Link to="/">Generate</Link>
         </li>
         
         <li>
            <Link to="/pricing">{isAuthenticated ? "Upgrade" : "Pricing"}</Link>
         </li>
      </ul>
   </nav>

   {/* Right-side Authentication Buttons (Right-aligned) */}
   <div className="auth-section" style={{ position: 'absolute', right: '0' }}>
      {!isAuthenticated ? (
         <div className="btn-sec d-flex">
            <Link to="/login" className="btn btn-without-hover btn-t me-3 d-lg-block d-none">
               Login
            </Link>
            <Link to="/login" className="btn btn-t me-3 d-lg-none d-flex gap-1 align-items-center justify-content-center">
               <BsStars />
               Login
            </Link>
            <Link to="/signup" className="btn">
               Sign Up
            </Link>
         </div>
      ) : (
         <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <p style={{ margin: 0,cursor: "pointer" }} className="creditsleft" onClick={() => navigate("/recharge")}>
               Credits left: {user?.credits?.toLocaleString('de-DE')}
            </p>
            <button className="btn" onClick={signOut}>
               Sign Out
            </button>
         </div>
      )}
   </div>
</div>
         
         </div>
   
      </header>
   );
};

export default Header;
