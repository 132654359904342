
import React, { useState , useEffect } from "react";
import { TbRosetteDiscountCheckFilled } from "react-icons/tb";
import "../Style/Pricing.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";

import AnimatedSection from "../Components/AnimatedSection";
import FAQSec from "../Components/FAQSec";
import PricingTableMobile from "../Components/PricingTableMobile";
import Testimonial from "../Components/Testimonial";
import { AuthFetch } from "../lib/auth";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { FiInfo } from "react-icons/fi";

const settings = {
   centerMode: true,
   centerPadding: "30",
   slidesToShow: 1,
   dots: true,
   infinite: true,
   speed: 500,
   arrows: false,
   focusOnSelect: true,
   autoplay: true,
   autoplaySpeed: 5000, // Slide change interval in milliseconds
   responsive: [
      {
         breakpoint: 768,
         settings: {
            slidesToShow: 1,
            centerMode: false,
         },
      },
      {
         breakpoint: 1024,
         settings: {
            slidesToShow: 1,
            centerMode: false,
         },
      },
   ],
};

const accordionData = [
   {
      title: "Is there a free trial available?",
      description:
         "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
   },
   {
      title: "Can I change my plan later?",
      description: `Absolutely, you can easily upgrade or downgrade your plan at any time to better suit your evolving needs. Just log in to your account and navigate to the settings to make the necessary changes.`,
   },
   {
      title: "What is your cancellation policy?",
      description: `We understand that circumstances may change, so we have a hassle-free cancellation policy. You can cancel your subscription at any time, and you will not be charged any further unless you reactivate your account.`,
   },
   {
      title: "Can other info be added to an invoice?",
      description: `Certainly, you can customize your invoices by adding additional information such as purchase order numbers, project details, or any other relevant data. Our system allows for flexibility to accommodate your specific requirements.`,
   },
   {
      title: "How does billing work?",
      description: `Our billing operates on a [monthly/yearly] subscription basis. You will be billed automatically at the beginning of each billing cycle. You can view your billing history and upcoming charges in your account settings.`,
   },
   {
      title: "How do I change my account email?",
      description: `To change your account email, simply log in to your account and navigate to the account settings. You will find the option to edit your email address there. Once you've made the necessary changes, make sure to verify your new email to ensure the security of your account.`,
   },
];
const daysLeft = () => {
   const today = new Date();
   const currentYear = today.getFullYear();
   const feb1 = today.getMonth() === 1 && today.getDate() > 1
       ? new Date(currentYear + 1, 1, 1) // February 1 of next year if today is past February
       : new Date(currentYear, 1, 1); // February 1 of the current year
   const diffInTime = feb1 - today; // Difference in milliseconds
   return Math.ceil(diffInTime / (1000 * 60 * 60 * 24)); // Convert to days
};

const Pricing = () => {
   const [loading, setLoading] = useState(false);
   const isAuthenticated = useAuth();
   const { userPlan } = useAuth();
   const navigate = useNavigate()
      const [daysLeftUntilJan, setDaysLeftUntilJan] = React.useState(daysLeft());

   const createCheckoutSession = async (priceId) => {
      setLoading(true)
      if (!isAuthenticated) {
         navigate('/login')
      }
      const { session } = await AuthFetch(`stripe/checkoutSession/${priceId}`, {
         method: 'GET'
      });
      window.location.href = session;   
   }
   useEffect(() => {
  document.title = "Flexible, No Subscription - Affordable PPC Advertising | PPCC.ai";
  
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", "Launch Search Ads in 60 seconds with AI. Only Pay for the Ads you Generate. Check out our Pricing and Start creating Google Ads with PPCC Today.");
  }

  const metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute("content", "PPC advertising, ai search ads, google ads ai, create search ads");
  }

  // Open Graph tags
  const ogTitle = document.querySelector('meta[property="og:title"]');
  if (ogTitle) {
    ogTitle.setAttribute("content", "Flexible, No Subscription - Affordable PPC Advertising | PPCC.ai");
  }

  const ogDescription = document.querySelector('meta[property="og:description"]');
  if (ogDescription) {
    ogDescription.setAttribute("content", "Launch Search Ads in 60 seconds with AI. Only Pay for the Ads you Generate. Check out our Pricing and Start creating Google Ads with PPCC Today.");
  }

  const ogImage = document.querySelector('meta[property="og:image"]');
  if (ogImage) {
    ogImage.setAttribute("content", "URL_to_image_for_preview");
  }

  const ogUrl = document.querySelector('meta[property="og:url"]');
  if (ogUrl) {
    ogUrl.setAttribute("content", "https://www.ppcc.ai/pricing");
  }

  // Twitter Card tags
  const twitterTitle = document.querySelector('meta[name="twitter:title"]');
  if (twitterTitle) {
    twitterTitle.setAttribute("content", "Flexible, No Subscription - Affordable PPC Advertising | PPCC.ai");
  }

  const twitterDescription = document.querySelector('meta[name="twitter:description"]');
  if (twitterDescription) {
    twitterDescription.setAttribute("content", "Launch Search Ads in 60 seconds with AI. Only Pay for the Ads you Generate. Check out our Pricing and Start creating Google Ads with PPCC Today.");
  }

  const twitterImage = document.querySelector('meta[name="twitter:image"]');
  if (twitterImage) {
    twitterImage.setAttribute("content", "URL_to_image_for_twitter");
  }

  const twitterCard = document.querySelector('meta[name="twitter:card"]');
  if (twitterCard) {
    twitterCard.setAttribute("content", "summary_large_image");
  }

}, []);

   return (
      <div classNamxe="main">
            <section className="table-sc Pricing-sec text-center space-mr-m ">
               <div className="container">
                  <AnimatedSection timingFunction="ease-out" delay={0.1}><div className="top-title mb-3 pb-2">{isAuthenticated ? "UPGRADE HERE" : "PRICING"}</div></AnimatedSection>
                  <AnimatedSection timingFunction="ease-out" delay={0.4}><h1 className="h1 pricingheadtitle">{isAuthenticated ? "Supercharge campaign creation. We got you" : "Supercharge campaign creation. Try for free"}</h1></AnimatedSection>
                  <AnimatedSection timingFunction="ease-out" delay={0.5}><div className="desc mb-4 mt-3 pricingdesc">Or pay as you go <a href="/recharge" class="linknostyle">here</a>. 1/20th of the actual cost, cheap enough?</div></AnimatedSection>
               </div>

               <div className="container container2">
                  <AnimatedSection><div className="transparent-table position-relative d-lg-block d-none">
                     <div class="table-inner position-relative overflow-hidden">
                        <table class="w-100">
                           <thead>
                              <tr>
                              <th>
                                    <div className="d-lg-flex f-row">
                                       <h3 class="tabletitle">$0 Monthly</h3>{" "}
                                       <div className="off-40">
                                          Flexible
                                       </div>
                                    </div>

                                    <p class="p-16">Enjoy our pay as you go model, purchase additional credits <a href="/recharge" class="linknostyle">here</a>.</p>
                                 </th>
                                 <th>
                                    <div className="amount">
                                       <span class="pricingamount">Free</span>/Month
                                    </div>

                                    <button 
      onClick={() => createCheckoutSession(process.env.REACT_APP_PRICE_ID_2500)} 
      className={`btn btn-sq ${userPlan === "free" ? "selectedbtn" : ""}`}>
      {!isAuthenticated ? "Create Account" : userPlan === "free" ? "Current" : "Downgrade"}
   </button>
                                 </th>
                                 <th>
                                    <div className="amount">
                                       <span class="pricingamount"><span class="dollarsignp">$</span>49.99</span>/Month
                                    </div>
                                    <button 
      onClick={() => createCheckoutSession(process.env.REACT_APP_PRICE_ID_15000)} 
      className={`btn btn-sq w-100 ${userPlan === "pro" ? "selectedbtn" : ""}`}>
      {!isAuthenticated ? "Choose this Plan" : userPlan === "pro" ? "Current" : userPlan === "free" ? "Upgrade" : "Downgrade"}
      {userPlan === "pro" && (
         <FiInfo 
            className="settings-icon" 
            onClick={(e) => {
               e.stopPropagation(); // Prevents triggering plan change
               window.open("https://billing.stripe.com/your_billing_url", "_blank");
            }}
         />
      )}
   </button>
                                 </th>
                                 <th>
                                    <div className="amount">
                                       <span class="pricingamount"><span class="dollarsignp">$</span>179.99</span>/Month
                                    </div>
                                    <button 
      onClick={() => createCheckoutSession(process.env.REACT_APP_PRICE_ID_45000)} 
      className={`btn btn-sq w-100 ${userPlan === "advanced" ? "selectedbtn" : ""}`}>
      {!isAuthenticated ? "Choose this Plan" : userPlan === "advanced" ? "Current" : "Upgrade"}
      {userPlan === "advanced" && (
         <FiInfo 
            className="settings-icon" 
            onClick={(e) => {
               e.stopPropagation();
               window.open("https://billing.stripe.com/your_billing_url", "_blank");
            }}
         />
      )}
   </button>
                                 </th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr>
                                 <td class="tabletitle">Monthly Credits</td>
                                 <td>2,500 Credits</td>
                                 <td>15,000 Credits</td>
                                 <td>45,000 Credits</td>
                              </tr>
                              <tr>
                                 <td class="tabletitle">Equivalent of</td>
                                 <td>10 Campaigns</td>
                                 <td>60 Campaigns</td>
                                 <td>180 Campaigns</td>
                              </tr>
                              <tr>
                                 <td class="tabletitle">Headines</td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                              </tr>{" "}
                              <tr>
                                 <td class="tabletitle">Descriptions</td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                              </tr>{" "}
                              <tr>
                                 <td class="tabletitle">Ad extensions</td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                              </tr>{" "}
                              <tr>
                                 <td class="tabletitle">Multiple editions</td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                              </tr>{" "}
                              <tr>
                                 <td class="tabletitle">Language settings</td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                              </tr>{" "}
                              <tr>
                                 <td class="tabletitle">Customizable prompts</td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                              </tr>{" "}
                              <tr>
                                 <td class="tabletitle">Cialdini principles</td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                                 <td><TbRosetteDiscountCheckFilled color="#fff" size={20} /></td>
                              </tr>
                           </tbody>
                        </table>
                        <motion.div
   className="gradient d-xl-block d-none"
   initial={{ left: "-10%", opacity: 0 }}
   animate={{ left: ["-10%", "90%"], opacity: [1, 1, 0] }}
   transition={{
      duration: 5.2, // Duration of the light animation
      ease: [0.2, 0.4, 1, 1], // Custom cubic-bezier for fast start and slow end
      repeat: Infinity,
      repeatDelay: 2.5,
      times: [0, 0.6, 1],
   }}
/>
<motion.div
   className="gradient-bottom d-xl-block d-none"
   initial={{ top: "-10%", opacity: 0 }}
   animate={{ top: ["-10%", "90%"], opacity: [1, 1, 0] }}
   transition={{
      duration: 5.2, // Duration of the light animation
      ease: [0.2, 0.4, 0.6, 1], // Custom cubic-bezier for fast start and slow end
      repeat: Infinity,
      repeatDelay: 2.5,
      times: [0, 0.6, 1],
   }}
/>
                     </div>
                  </div>
   </AnimatedSection>
                  <div className="d-block d-lg-none">
                     <PricingTableMobile />
                  </div>
               </div>
            </section>
            {/*
            TESTIMONIALSECTION
            <section className="testimonial-sc text-center space-mr-m">
               <div className="container-fluid overflow-hidden pb-5">
                  <div className="testimonial-sec-title-main">
                     <AnimatedSection><div className="subtitlepricingabove top-title mb-3 pb-2">TESTIMONIALS</div></AnimatedSection>
                     <AnimatedSection><div className="h2 subtitlepricing specialtitle">Don't take our word for it. Over 100+ people trust us</div></AnimatedSection>
                     <AnimatedSection><div className="desc mb-4 mt-3 mobiledisplaynone testip">A solution for agencies all across the globe.</div></AnimatedSection>
                  </div>
                  <AnimatedSection>
                     <div><Testimonial />*/}
                     
                     {/* <div className="testimonial-slider pt-5">
                        <Slider {...settings}>
                           <div className="testimonial-block text-start">
                              <div className="logo-t mb-4">⚡</div>
                              <div className="title mb-4">
                                 “This incredible AI web app has transformed the way I manage data and streamline
                                 processes.”
                              </div>
                              <div className="content mb-4">
                                 Their creativity, attention to detail, and prompt communication set them apart. Our
                                 website looks stunning, and our online campaigns are driving great results.
                              </div>
                              <div className="profile-sc d-flex align-items-center">
                                 <div className="img-sc">
                                    <img src="images/pf.jpg" alt="" />
                                 </div>
                                 <div className="pf-cnt ms-3">
                                    <p className="m-0 text-white">Sarah Johnson</p>
                                    <p className="m-0 text-white">Sarah Johnson</p>
                                 </div>
                              </div>
                           </div>
                           <div className="testimonial-block text-start">
                              <div className="logo-t mb-4">⚡</div>
                              <div className="title mb-4">
                                 “This incredible AI web app has transformed the way I manage data and streamline
                                 processes.”
                              </div>
                              <div className="content mb-4">
                                 Their creativity, attention to detail, and prompt communication set them apart. Our
                                 website looks stunning, and our online campaigns are driving great results.
                              </div>
                              <div className="profile-sc d-flex align-items-center">
                                 <div className="img-sc">
                                    <img src="images/pf.jpg" alt="" />
                                 </div>
                                 <div className="pf-cnt ms-3">
                                    <p className="m-0">Sarah Johnson</p>
                                    <p className="m-0">Sarah Johnson</p>
                                 </div>
                              </div>
                           </div>
                           <div className="testimonial-block text-start">
                              <div className="logo-t mb-4">⚡</div>
                              <div className="title mb-4">
                                 “This incredible AI web app has transformed the way I manage data and streamline
                                 processes.”
                              </div>
                              <div className="content mb-4">
                                 Their creativity, attention to detail, and prompt communication set them apart. Our
                                 website looks stunning, and our online campaigns are driving great results.
                              </div>
                              <div className="profile-sc d-flex align-items-center">
                                 <div className="img-sc">
                                    <img src="images/pf.jpg" alt="" />
                                 </div>
                                 <div className="pf-cnt ms-3">
                                    <p className="m-0">Sarah Johnson</p>
                                    <p className="m-0">Sarah Johnson</p>
                                 </div>
                              </div>
                           </div>
                           <div className="testimonial-block text-start">
                              <div className="logo-t mb-4">⚡</div>
                              <div className="title mb-4">
                                 “This incredible AI web app has transformed the way I manage data and streamline
                                 processes.”
                              </div>
                              <div className="content mb-4">
                                 Their creativity, attention to detail, and prompt communication set them apart. Our
                                 website looks stunning, and our online campaigns are driving great results.
                              </div>
                              <div className="profile-sc d-flex align-items-center">
                                 <div className="img-sc">
                                    <img src="images/pf.jpg" alt="" />
                                 </div>
                                 <div className="pf-cnt ms-3">
                                    <p className="m-0">Sarah Johnson</p>
                                    <p className="m-0">Sarah Johnson</p>
                                 </div>
                              </div>
                           </div>
                        </Slider>
                     </div> */}
{/*   </div>
                  </AnimatedSection>
               </div>
            </section>
      */}
         <FAQSec />
         {/* <AnimatedSection>
            <section className="faq-sc text-center space-mr-m mb-5">
               <div className="container">
                  <div className="row justify-content-center">
                     <div className="col-md-7">
                        <div className="h2 subtitlepricing titleminmargintop">Frequently asked questions</div>
                        <AnimatedSection>
                           <div class="desc p-18 faqp">Everything you need to know about the product and billing.</div>
                        </AnimatedSection>
                        <AnimatedSection>
                           <div class="accordion accordion-flush text-start mt-5" id="accordionFlushExample">
                              {accordionData?.map((item, index) => {
                                 return (
                                    <div class="accordion-item">
                                       <h2 class="accordion-header" id={"faq_heading" + index}>
                                          <button
                                             class="accordion-button collapsed"
                                             type="button"
                                             data-bs-toggle="collapse"
                                             data-bs-target={"#faq" + index}
                                             aria-expanded="false"
                                             aria-controls={"faq" + index}
                                          >
                                             {item.title}
                                          </button>
                                       </h2>
                                       <div
                                          id={"faq" + index}
                                          class="accordion-collapse collapse"
                                          aria-labelledby={"faq" + index}
                                          data-bs-parent="#accordionFlushExample"
                                       >
                                          <div class="accordion-body">{item.description}</div>
                                       </div>
                                    </div>
                                 );
                              })}
                           </div>
                        </AnimatedSection>
                     </div>
                  </div>
               </div>
            </section>
         </AnimatedSection> */}
         <AnimatedSection>
            <section className="question-sc text-center">
               <div className="container">
                  <div className="inner-sc">
                     <div className="img-flex d-flex justify-content-center imagesquestions">
                        <div className="img-sc lft">
                           <img src="images/linkedlogo.png" alt="" />
                        </div>
                        <div className="img-sc mdl">
                           <img src="images/emaillogo.png" alt="" />
                        </div>
                        <div className="img-sc rgt">
                           <img src="images/xtwitterlogo.png" alt="" />
                        </div>
                     </div>
                     <div>
                        <h5>We are here. Every day.</h5>
                        <p className="mb-0 p-16">Can’t find the answer you’re looking for? Don't hesitate to reach out.</p>
                     </div>
                     <a class="btn btn-sq mx-auto" href="mailto:suppport@ppcc.ai">
                        Contact the Team
                     </a>
                  </div>
               </div>
            </section>
         </AnimatedSection>
      </div>
   );
};

export default Pricing;
