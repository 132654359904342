import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";

const HeightAnimatedSection = ({ children, delay }) => {
   const ref = useRef(null);

   return (
      <motion.div
         initial={{ opacity: 0, y: 50 }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ 
            duration: 0.5, 
            ease: "easeOut", 
            delay: delay || 0 
         }}
         style={{ 
            position: 'relative',
            willChange: 'transform',
            transformOrigin: 'top'
         }}
         ref={ref}
      >
         {children}
      </motion.div>
   );
};

export default HeightAnimatedSection;
