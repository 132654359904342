import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const getRandomOffset = () => ({
  x: (Math.random() - 0.5) * 10,
  y: (Math.random() - 0.5) * 10,
  rotate: (Math.random() - 0.5) * 15,
});

const CornerWrapAnimation = ({ fadeOut }) => { // No need to rename here
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowAnimation(true), 1500);
    return () => clearTimeout(timer);
  }, []);

  const corners = [
    { className: "top-left", custom: getRandomOffset() },
    { className: "top-right", custom: getRandomOffset() },
    { className: "bottom-right", custom: getRandomOffset() },
    { className: "bottom-left", custom: getRandomOffset() },
  ];

  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: fadeOut ? 0 : 1 }}
      transition={{ duration: 1, ease: "easeOut" }} // Smooth fade out
    >
      {showAnimation &&
        corners.map((corner, index) => (
          <motion.div
            key={index}
            className={`corner-wrap ${corner.className}`}
            custom={corner.custom}
            initial={{
              opacity: 0,
              x: corner.custom.x,
              y: corner.custom.y,
              rotate: corner.custom.rotate,
            }}
            animate={{
              opacity: 1,
              x: 0,
              y: 0,
              rotate: 0,
              transition: { duration: 0.5, ease: "easeOut" },
            }}
          />
        ))}
    </motion.div>
  );
};

export default CornerWrapAnimation;
