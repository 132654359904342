import React, { useEffect, useRef, useState } from "react";
import "../Style/blogtemplate.css";
import AnimatedSection from "../Components/AnimatedSection";

const data2 = [
   {
      title: "Boost Engagement by 36%",
      image: "/images/im1.png",
      description:
         "Studies show that well-crafted descriptions can boost engagement by up to 36%, and increase your ROAS.",
   },
   {
      title: "Raise Conversions by 50%",
      image: "/images/im2.png",
      description:
         "Descriptions that align with user intent can raise conversion rates by as much as 50%. PPCC.AI automates this process, allowing you to efficiently craft converting descriptions.",
   },
   {
      title: "It’s about the Journey, and Destination",
      image: "/images/im3.png",
      description:
         "The descriptions make up an integral part of your user journey, moving users seamlessly from ad to landing page. PPCC.AI crafts engaging descriptions that focus on driving results.",
   },
];
 
 const Resource = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [enterpriseActiveIndex, setEnterpriseActiveIndex] = useState(0);
    const [heights, setHeights] = useState([]);
    const researchIntervalRef = useRef(null);
    const enterpriseIntervalRef = useRef(null);
 
    const blockCount = data2.length;
    const enterpriseBlockCount = data2.length;
 
    const startResearchInterval = () => {
       researchIntervalRef.current = setInterval(() => {
          setActiveIndex((prevIndex) => (prevIndex + 1) % blockCount);
       }, 3000);
    };
 
    const clearResearchInterval = () => {
       if (researchIntervalRef.current) {
          clearInterval(researchIntervalRef.current);
          researchIntervalRef.current = null; // Reset the ref after clearing
       }
    };
 
    const startEnterpriseInterval = () => {
       enterpriseIntervalRef.current = setInterval(() => {
          setEnterpriseActiveIndex((prev) => (prev + 1) % enterpriseBlockCount);
       }, 3000);
    };
 
    const clearEnterpriseInterval = () => {
       if (enterpriseIntervalRef.current) {
          clearInterval(enterpriseIntervalRef.current);
          enterpriseIntervalRef.current = null;
       }
    };
 
    useEffect(() => {
       // const interval = setInterval(() => {
       //    setActiveIndex((prevIndex) => (prevIndex + 1) % blockCount);
       // }, 3000);
 
       // return () => clearInterval(interval);
 
       startResearchInterval();
       startEnterpriseInterval();
 
       return () => {
          clearResearchInterval();
          clearEnterpriseInterval();
       };
    }, []);
 
    useEffect(() => {
       const calculateHeights = () => {
          const btmDivs = document.querySelectorAll(".btm");
          const newHeights = Array.from(btmDivs).map((div) => div.scrollHeight);
          setHeights(newHeights);
       };
 
       calculateHeights();
       window.addEventListener("resize", calculateHeights);
 
       return () => {
          window.removeEventListener("resize", calculateHeights);
       };
    }, []);

    useEffect(() => {
      document.title = "Optimized Descriptions | PPCC.ai";
    
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", "Boost engagement and conversions with optimized ad descriptions. PPCC AI crafts descriptions that align with user intent and drive results.");
      }
    
      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute("content", "ad descriptions, PPC engagement, conversion rates, user intent, PPCC.ai");
      }
    
      // Open Graph tags
      const ogTitle = document.querySelector('meta[property="og:title"]');
      if (ogTitle) {
        ogTitle.setAttribute("content", "Optimized Descriptions | PPCC.ai");
      }
    
      const ogDescription = document.querySelector('meta[property="og:description"]');
      if (ogDescription) {
        ogDescription.setAttribute("content", "Use PPCC AI to create high-impact ad descriptions that boost engagement and conversions by aligning with user intent.");
      }
    
      const ogImage = document.querySelector('meta[property="og:image"]');
      if (ogImage) {
        ogImage.setAttribute("content", "URL_to_image_for_preview");
      }
    
      const ogUrl = document.querySelector('meta[property="og:url"]');
      if (ogUrl) {
        ogUrl.setAttribute("content", "https://www.ppcc.ai/descriptions");
      }
    
      // Twitter Card tags
      const twitterTitle = document.querySelector('meta[name="twitter:title"]');
      if (twitterTitle) {
        twitterTitle.setAttribute("content", "Optimized Descriptions | PPCC.ai");
      }
    
      const twitterDescription = document.querySelector('meta[name="twitter:description"]');
      if (twitterDescription) {
        twitterDescription.setAttribute("content", "Boost ad performance with descriptions that enhance engagement and conversions, powered by PPCC AI.");
      }
    
      const twitterImage = document.querySelector('meta[name="twitter:image"]');
      if (twitterImage) {
        twitterImage.setAttribute("content", "URL_to_image_for_twitter");
      }
    
      const twitterCard = document.querySelector('meta[name="twitter:card"]');
      if (twitterCard) {
        twitterCard.setAttribute("content", "summary_large_image");
      }
    }, []);
    

return (
    <div className="main">
       <section className="banner banner-resources text-center ">
       <div className="desktoponly"><div className="container firstsectionre">
             <AnimatedSection delay={0.1}>
                <div className="top-title mb-4 pb-1">More information</div>
             </AnimatedSection>
             <AnimatedSection delay={0.4}>
                <h1 className="h1">
                   About Descriptions
                </h1>
             </AnimatedSection>
             <AnimatedSection delay={0.5}>
                <div className="desc mb-4 mt-3 resource-banner-title">
                Spark engagement and conversions with clear descriptions.
                </div>
             </AnimatedSection>
             <div class="blogsettings nomobile">
      
    <div
  className="moresettingsgen fade-in"
  style={{
    transition: "opacity 0.7s ease, transform 0.3s ease",
    transitionDelay: "0.2s",
    opacity: 1,
    transform: "translateY(0)",
    pointerEvents: "auto",
  }}
>
<textarea
  className="form-control custominform dynamic-textarea"
  rows="4"
  value={`- Capitalize for readability.
- Clear, concise, actionable.
- Focus on product value.
- 90 characters max.`}
  readOnly
></textarea>

  <div className="settingsselect">
    <div className="custom-dropdown selectcm">
      <div className="custom-dropdown-selected">
        Language
        <svg
          className="custom-dropdown-chevron"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="16"
          height="16"
        >
          <path fill="white" d="M7 10l5 5 5-5z" />
        </svg>
      </div>
    </div>

    <div className="custom-dropdown selectcp">
      <div className="custom-dropdown-selected">
        Cialdini principle
        <svg
          className="custom-dropdown-chevron"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="16"
          height="16"
        >
          <path fill="white" d="M7 10l5 5 5-5z" />
        </svg>
      </div>
    </div>
  </div>

  <div className="thirdsettingsline">
    <div
      className="label-sc1 align-items-center"
      style={{ fontSize: "14px", marginTop: "10px" }}
    >
      <label htmlFor="number_of_ads" className="me-2">
        Amount of ads:
      </label>
      <span className="minus-sign minus-sign-amount">-</span>
      <input
        placeholder="Enter number"
        className="amountofads realamount"
        type="number"
        id="number_of_ads"
        name="number_of_ads"
        value="1"
        min="1"
        max="5"
        readOnly
      />
      <span className="plus-sign">+</span>
    </div>
    <div
      className="label-keywords label-sc1 align-items-center"
      style={{ fontSize: "14px", marginTop: "10px" }}
    >
      <label htmlFor="number_of_ads" className="me-2">
        Amount of keywords:
      </label>
      <span className="minus-sign minus-sign-amount">-</span>
      <input
        placeholder="Enter number"
        className="amountofads"
        type="number"
        id="number_of_ads"
        name="number_of_ads"
        value="5"
        min="5"
        max="20"
      />
      <span className="plus-sign">+</span>
    </div>
  </div>
</div>
<div class="centered">
<div className="menu-settings">
    <label className="label-sc clickable-label">
      <input type="radio" name="menuOptions" value="option1" />
      <span>Headlines</span>
    </label>
    <label className="label-sc clickable-label selectedblog">
      <input type="radio" name="menuOptions" value="option2" />
      <span>Descriptions</span>
    </label>
    <label className="label-sc clickable-label">
      <input type="radio" name="menuOptions" value="option2" />
      <span>Sitelinks</span>
    </label>
    <label className="label-sc clickable-label">
      <input type="radio" name="menuOptions" value="option2" />
      <span>Snippets</span>
    </label>
    <label className="label-sc clickable-label">
      <input type="radio" name="menuOptions" value="option2" />
      <span>Callouts</span>
    </label>
    <label className="label-sc clickable-label">
      <input type="radio" name="menuOptions" value="option2" />
      <span>Keywords</span>
    </label>
  </div></div>

    </div>
          </div></div>
             <div className="container firstsectionre firstsectionremobile mobileonly">
             <AnimatedSection delay={0.1}>
                <div className="top-title mb-4 pb-1">More information</div>
             </AnimatedSection>
                                  <AnimatedSection delay={0.4}>
                <h1 className="h1 mobiletitle">
                About Descriptions
                </h1>
             </AnimatedSection>
             <AnimatedSection delay={0.5}>
                <div className="desc mb-4 mt-3 resource-banner-title">
                Spark engagement and conversions with clear descriptions.
                </div>
             </AnimatedSection>
             </div>
             <AnimatedSection><div className="infosec1"><div className="containersec1">
    <h4 className="textwhite itemtitle agencytitle">Successful Descriptions are...</h4>
    <ul className="p-18 itemdesc">
        <li className="listbullets">Clear and direct.</li>
        <li className="listbullets">Matched to user intent.</li>
        <li className="listbullets">Designed to build trust and drive clicks.</li>
    </ul>
    <p className="p-18 itemdesc">If headlines are the bones of your ad, then descriptions are the meat. Effective descriptions are able to convince users that your business is right for them.</p>
    <div className="scroll-arrow-container">
        <div className="scroll-arrow">&#x276F;</div>
    </div>
</div></div></AnimatedSection>

<div className="informativesection"><div className="containersec2">
    <AnimatedSection><h3 className="textwhite itemtitle agencytitle headlineblog2">How our Descriptions work</h3></AnimatedSection>
    <ul className="p-18 itemdesc">
        <AnimatedSection><li className="listbullets">Keeps sentences accessible to a broad audience.</li></AnimatedSection>
        <AnimatedSection><li className="listbullets">Ensures a minimum word count for full impact.</li></AnimatedSection>
        <AnimatedSection><li className="listbullets">Detects key selling descriptors for your business.</li></AnimatedSection>
    </ul>
    <AnimatedSection><p className="p-18 itemdesc">Our AI creates optimised descriptions which may otherwise take hours to refine for clarity, impact, and engagement.</p></AnimatedSection>
    <div class="blogsettings nomobile">
      
    <div
  className="moresettingsgen fade-in"
  style={{
    transition: "opacity 0.7s ease, transform 0.3s ease",
    transitionDelay: "0.2s",
    opacity: 1,
    transform: "translateY(0)",
    pointerEvents: "auto",
  }}
>
<textarea
  className="form-control custominform dynamic-textarea"
  rows="4"
  value={`- Capitalize for readability.
- Clear, concise, actionable.
- Focus on product value.
- 90 characters max.`}
  readOnly
></textarea>

  <div className="settingsselect">
    <div className="custom-dropdown selectcm">
      <div className="custom-dropdown-selected">
        Language
        <svg
          className="custom-dropdown-chevron"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="16"
          height="16"
        >
          <path fill="white" d="M7 10l5 5 5-5z" />
        </svg>
      </div>
    </div>

    <div className="custom-dropdown selectcp">
      <div className="custom-dropdown-selected">
        Cialdini principle
        <svg
          className="custom-dropdown-chevron"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="16"
          height="16"
        >
          <path fill="white" d="M7 10l5 5 5-5z" />
        </svg>
      </div>
    </div>
  </div>

  <div className="thirdsettingsline">
    <div
      className="label-sc1 align-items-center"
      style={{ fontSize: "14px", marginTop: "10px" }}
    >
      <label htmlFor="number_of_ads" className="me-2">
        Amount of ads:
      </label>
      <span className="minus-sign minus-sign-amount">-</span>
      <input
        placeholder="Enter number"
        className="amountofads realamount"
        type="number"
        id="number_of_ads"
        name="number_of_ads"
        value="1"
        min="1"
        max="5"
        readOnly
      />
      <span className="plus-sign">+</span>
    </div>
    <div
      className="label-keywords label-sc1 align-items-center"
      style={{ fontSize: "14px", marginTop: "10px" }}
    >
      <label htmlFor="number_of_ads" className="me-2">
        Amount of keywords:
      </label>
      <span className="minus-sign minus-sign-amount">-</span>
      <input
        placeholder="Enter number"
        className="amountofads"
        type="number"
        id="number_of_ads"
        name="number_of_ads"
        value="5"
        min="5"
        max="20"
      />
      <span className="plus-sign">+</span>
    </div>
  </div>
</div>
<div class="centered">
<div className="menu-settings">
    <label className="label-sc clickable-label">
      <input type="radio" name="menuOptions" value="option1" />
      <span>Headlines</span>
    </label>
    <label className="label-sc clickable-label selectedblog">
      <input type="radio" name="menuOptions" value="option2" />
      <span>Descriptions</span>
    </label>
    <label className="label-sc clickable-label">
      <input type="radio" name="menuOptions" value="option2" />
      <span>Sitelinks</span>
    </label>
    <label className="label-sc clickable-label">
      <input type="radio" name="menuOptions" value="option2" />
      <span>Snippets</span>
    </label>
    <label className="label-sc clickable-label">
      <input type="radio" name="menuOptions" value="option2" />
      <span>Callouts</span>
    </label>
    <label className="label-sc clickable-label">
      <input type="radio" name="menuOptions" value="option2" />
      <span>Keywords</span>
    </label>
  </div></div>

    </div>


</div></div>

       </section>
       <AnimatedSection>
          <section className="resources-slider enterprise-block-main mt-5 py-5 newenterpriseblock">
             <div className="container desktoplarge">
                <div className="resource-banner1 agencybanner">
                   <div className="mb-2 d-flex align-items-center justify-content-center">
                      <h2 className="text-white">Key Insights</h2>
                   </div>
                   <div className="desc mb-4 p-18">
                   Engage your audience with clarity and build trust.
                   </div>
                                     <div className="mobile-image-slider mobileonly">
 <img 
    src={data2[enterpriseActiveIndex].image} 
    alt={`Image for ${data2[enterpriseActiveIndex].title}`} 
    className="block-image"
 />
</div>
                   <div className=" text-start">
                      <div className="position-relative">
                         <div className="row align-items-center">
                            <div className="col-md-6">
                               <div className="vertical-slider">
                                  {data2.map((block, index) => (
                                     <div
                                        key={index}
                                        className={`vertical-block ${
                                           index === enterpriseActiveIndex ? "active" : ""
                                        }`}
                                        style={{
                                           cursor: "pointer",
                                        }}
                                        onClick={() => {
                                           clearEnterpriseInterval();
                                           setEnterpriseActiveIndex(index);
                                           startEnterpriseInterval();
                                        }}
                                     >
                                        <h4 class="itemtitle agencytitle newagencytitle">{block.title}</h4>
                                        <div
                                           className="btm"
                                           style={{
                                              height: index === enterpriseActiveIndex ? heights[index] : 0,
                                              transition: "height 0.5s, opacity 0.5s",
                                              opacity: index === enterpriseActiveIndex ? 1 : 0,
                                              overflow: "hidden",
                                           }}
                                        >
                                           <p className="p-18 itemdesc newitemdesc">{block.description}</p>
                                        </div>
                                     </div>
                                  ))}
                               </div>
                            </div>
                            <div className="col-md-5 rgt">
                               <div className="vertical-slider image-slider desktoponly">
                                  {data2.map((block, index) => (
                                     <div
                                        key={index}
                                        className={`vertical-block ${
                                           index === enterpriseActiveIndex ? "active" : ""
                                        }`}
                                     >
                                        <img src={block.image} alt="Block Image" className="block-image" />
                                     </div>
                                  ))}
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </section>
       </AnimatedSection>
       <div className="informativesection moreblogssec">
       <AnimatedSection><h3 className="textwhite agencytitle itemtitle learnmoreabout mobileonly">Learn more about</h3></AnimatedSection>
       <div class="mobileonly">
    <div className="moreblogsitem moreblogsitemfirst">
        <AnimatedSection>
            <a href="/headlines" className="">
                <p className="p-18 itemdesc btnzehma"><span className="nextblogbtn">Headlines <span className="littlarrow">&#x203A;</span></span></p>
            </a>
        </AnimatedSection>
        <AnimatedSection className="animated-section">
            <p className="p-18 itemdesc">Crafting headlines that resonate can transform ad performance, but it’s often a challenge to get it just right.</p>
        </AnimatedSection>
    </div>
    <div className="moreblogsitem moreblogsitemfirst">
        <AnimatedSection>
            <a href="/sitelinks" className="">
                <p className="p-18 itemdesc btnzehma"><span className="nextblogbtn">Sitelinks <span className="littlarrow">&#x203A;</span></span></p>
            </a>
        </AnimatedSection>
        <AnimatedSection className="animated-section">
            <p className="p-18 itemdesc">Widen your reach with sitelinks that add value and enhance user experience.</p>
        </AnimatedSection>
    </div>
    <div className="moreblogsitem firstitemsecondcolumn">
        <AnimatedSection>
            <a href="/callouts" className="">
                <p className="p-18 itemdesc btnzehma"><span className="nextblogbtn">Callouts <span className="littlarrow">&#x203A;</span></span></p>
            </a>
        </AnimatedSection>
        <AnimatedSection className="animated-section">
            <p className="p-18 itemdesc">Strengthen your ad’s message with callouts that highlight key features and guide users toward conversion.</p>
        </AnimatedSection>
    </div>
    <div className="moreblogsitem">
        <AnimatedSection>
            <a href="/structured-snippets" className="">
                <p className="p-18 itemdesc btnzehma"><span className="nextblogbtn">Structured Snippets <span className="littlarrow">&#x203A;</span></span></p>
            </a>
        </AnimatedSection>
        <AnimatedSection className="animated-section">
            <p className="p-18 itemdesc">Structured snippets can be tricky—non-compliance can lower quality scores and increase ad costs.</p>
        </AnimatedSection>
    </div>
</div>
<div class="itemsvoordesktopcolumn">
    <div className="moreblogsitem moreblogsitemfirst">
        <a href="/headlines" className="">
            <p className="p-18 itemdesc btnzehma"><span className="nextblogbtn">Headlines <span className="littlarrow">&#x203A;</span></span></p>
        </a>
        <p className="p-18 itemdesc">Crafting headlines that resonate can transform ad performance, but it’s often a challenge to get it just right.</p>
    </div>
    <div className="moreblogsitem moreblogsitemfirst">
        <a href="/sitelinks" className="">
            <p className="p-18 itemdesc btnzehma"><span className="nextblogbtn">Sitelinks <span className="littlarrow">&#x203A;</span></span></p>
        </a>
        <p className="p-18 itemdesc">Widen your reach with sitelinks that add value and enhance user experience.</p>
    </div>
    <div className="moreblogsitem firstitemsecondcolumn">
        <a href="/callouts" className="">
            <p className="p-18 itemdesc btnzehma"><span className="nextblogbtn">Callouts <span className="littlarrow">&#x203A;</span></span></p>
        </a>
        <p className="p-18 itemdesc">Strengthen your ad’s message with callouts that highlight key features and guide users toward conversion.</p>
    </div>
    <div className="moreblogsitem">
        <a href="/structured-snippets" className="">
            <p className="p-18 itemdesc btnzehma"><span className="nextblogbtn">Structured Snippets <span className="littlarrow">&#x203A;</span></span></p>
        </a>
        <p className="p-18 itemdesc">Structured snippets can be tricky—non-compliance can lower quality scores and increase ad costs.</p>
    </div>
</div>
             </div>
    </div>
 );
};

export default Resource;
